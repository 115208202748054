import {Media} from "../types/Media";
import MediaLibraryService from "./MediaLibraryService";
import API, {getApiErrorMessage} from "@ova-studio/api-helper";
import {SimpleCallback} from "@ova-studio/react-hyper-admin";
import {MediaType} from "../types/MediaType";
import {MediaEditResource} from "../types/MediaEditResource";

type MediaEventType = 'created' | 'updated' | 'deleted';
type MediaEventListenerMap = {
    created: (media: Media) => void,
    updated: (media: Media) => void,
    deleted: (media: Media) => void,
};

type MediaEventListener<T extends MediaEventType> = MediaEventListenerMap[T];
type MediaEventMap = {
    [K in MediaEventType]: MediaEventListener<K>[];
};

export default class MediaManager {
    private readonly _service: MediaLibraryService;

    private _listeners: MediaEventMap = {
        created: [],
        updated: [],
        deleted: [],
    };

    constructor(service: MediaLibraryService) {
        this._service = service;

        if (this._service.isWsEnabled) {
            this._service.app.websocket.listen('MediaLibrary.Global', '.media.created', (data : Media) => {
                this.fire('created', data);
            });
            this._service.app.websocket.listen('MediaLibrary.Global', '.media.updated', (data : Media) => {
                this.fire('updated', data);
            });
            this._service.app.websocket.listen('MediaLibrary.Global', '.media.deleted', (data : Media) => {
                this.fire('deleted', data);
            });
        }
    }

    public on<T extends MediaEventType>(event: T, listener: MediaEventListener<T>) : SimpleCallback {
        this._listeners = {
            ...this._listeners,
            [event]: [...this._listeners[event], listener],
        }

        return () => {
            this._listeners = {
                ...this._listeners,
                [event]: this._listeners[event].filter(l => l !== listener),
            }
        };
    }

    public fire<T extends MediaEventType>(event: T, data: Media) {
        const listeners = this._listeners[event] as MediaEventListener<T>[];

        if (listeners.length > 0) {
            listeners.forEach(l => l(data));
        }
    }

    public async openMediaEdit(id: Media['id']) {
        if (!this._service.states) {
            throw new Error('MediaLibraryService states not set');
        }

        this._service.states.mediaEditModal.setData(null);
        this._service.states.mediaEditModal.open();

        try {
            const media = await this._service.loadMedia(id);
            this._service.states.mediaEditModal.setData(media);
        } catch (e) {
            this._service.states.mediaEditModal.close();
            this._service.app.toasts.createToast({
                title: 'Помилка завантаження даних',
                body: getApiErrorMessage(e),
                variant: 'danger',
            });
        }
    }

    public saveMediaImageEdit(id: Media['id'], filename: Media['filename'], imageCanvas: HTMLCanvasElement, state: object) : Promise<void> {
        const endpoint = this._service.getEndpoint(`media/${id}/edit`);

        return new Promise<void>((resolve, reject) => {
            imageCanvas.toBlob(async (blob) => {
                if (!blob) {
                    reject(new Error('Failed to save image'));
                    return;
                }

                const file = new File([blob], `edited-${filename}.jpg`);

                try {
                    const response = await API.postWithFile(endpoint, { image: file, state: JSON.stringify(state) });
                    resolve();

                    this._service.upload.addMedia(response.data as Media);
                } catch (e) {
                    reject(new Error(getApiErrorMessage(e)));
                }
            }, 'image/jpeg', 0.9);
        });
    }

    public async openMediaImageEdit(id: Media['id']) {
        if (!this._service.states) {
            throw new Error('MediaLibraryService states not set');
        }

        this._service.states.mediaImageEditorModal.setData(null);
        this._service.states.mediaImageEditorModal.open();

        try {
            const media = await API.getData<MediaEditResource>(this._service.getEndpoint(`media/${id}/edit`));

            if (media.type !== MediaType.Image) {
                this._service.states.mediaImageEditorModal.close();
                this._service.app.toasts.createToast({
                    title: 'Помилка',
                    body: 'Можна редагувати тільки зображення',
                    variant: 'danger',
                });
                return;
            }

            this._service.states.mediaImageEditorModal.setData(media);
        } catch (e) {
            this._service.states.mediaEditModal.close();
            this._service.app.toasts.createToast({
                title: 'Помилка завантаження даних',
                body: getApiErrorMessage(e),
                variant: 'danger',
            });
        }
    }

    public async handleEditFormSubmit(id: Media['id'], data: Record<string, any>) {
        const endpoint = this._service.getEndpoint(`media/${id}`);
        const { data: newData } = await API.put(endpoint, data);

        this.fire('updated', newData as Media);

        this._service.states?.mediaEditModal.close();
    }

    public async openMediaData(media: Media[]) {
        const items = media.map(m => ({
            id: m.id,
            thumb: m.thumb?.url ?? null,
            name: m.meta_data?.name ?? '',
            description: m.meta_data?.description ?? '',
        }));

        if (!this._service.states) {
            throw new Error('MediaLibraryService states not set');
        }

        this._service.states.dataModal.setData({ items });
        this._service.states.dataModal.open();
    }

    public async handleDataFormSubmit(data: Record<string, any>) {
        const endpoint = this._service.getEndpoint(`media/data`);
        const { data: newData } = await API.post(endpoint, data) as { data: Media[] };

        newData.forEach(media => {
            this.fire('updated', media);
        });

        this._service.states?.dataModal.close();
    }

    public async openMediaDelete(id: Media['id']) {
        if (!this._service.states) {
            throw new Error('MediaLibraryService states not set');
        }

        this._service.states.deleteModal.setData(null);
        this._service.states.deleteModal.open();

        try {
            const media = await this._service.loadMedia(id);
            this._service.states.deleteModal.setData({
                text: `Видалити медіа ${media.filename}?`,
                onConfirm: () => this.handleMediaDelete(media),
            });
        } catch (e) {
            this._service.states.deleteModal.close();
            this._service.app.toasts.createToast({
                title: 'Помилка завантаження даних',
                body: getApiErrorMessage(e),
                variant: 'danger',
            });
        }
    }

    public async handleMediaDelete(media: Media) {
        const endpoint = this._service.getEndpoint(`media/${media.id}`);
        await API.delete(endpoint);

        this.fire('deleted', media);
    }

}
