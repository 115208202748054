import {isMediaType, MediaType} from "./MediaType";

export type MediaLibraryConfigData = {
    endpoint: string,
    types?: MediaType[],
    enableWs?: boolean,
}

export const isMediaLibraryConfigData = (data: any) : data is MediaLibraryConfigData => {
    return typeof data === 'object'
        && typeof data.endpoint === 'string'
        && (
            data.types === undefined
            || (Array.isArray(data.types) && data.types.every(isMediaType))
        );
}
