import * as React from "react";
import {BouncingLoader, Form, Modal, UseModalReturn} from "@ova-studio/react-hyper-admin";
import {Media} from "../types/Media";
import {Alert, Card, Col, Image, Row} from "react-bootstrap";
import {useMediaLibraryService} from "../services/MediaLibraryService";

// @ts-ignore
import fallbackThumb from '../assets/no-image.png';

type MediaDataModalItemData = {
    id: Media['id'],
    thumb: string|null,
    name: string,
    description: string,
}
type MediaDataModalData = { items: MediaDataModalItemData[] }
export type MediaDataModalState = UseModalReturn<MediaDataModalData>

type MediaDataModalProps = {
    state: MediaDataModalState,
}

const MediaDataModalItems = () => {
    const { fields } = Form.useFieldArray({ name: 'items' });

    return (
        <React.Fragment>
            {fields.map((field, idx) => (
                <MediaDataModalItem key={field.id} idx={idx} />
            ))}
        </React.Fragment>
    )
}

const MediaDataModalItem = ({ idx } : { idx: number }) => {
    const thumb = Form.useWatch({ name: `items.${idx}.thumb` });
    return (
        <Card body className='mb-1'>
            <Row>
                <Col lg={4}>
                    <Image src={thumb ? thumb : fallbackThumb} rounded thumbnail className='w-100' />
                </Col>
                <Col>
                    <Form.Input name={`items.${idx}.name`} label='Назва' />
                    <Form.Input name={`items.${idx}.description`} label='Опис' type='textarea' rows={4} groupClass='' />
                </Col>
            </Row>
        </Card>
    )
}

const MediaDataModalBody = ({ data }: { data: MediaDataModalData }) => {

    const mediaManager = useMediaLibraryService().mediaManager;

    return (
        <Form.Callback data={data} handleSubmit={mediaManager.handleDataFormSubmit.bind(mediaManager)} showSuccess={false}>
            <Modal.Body>
                <Alert variant='info'>
                    Для роботи розширених результатів пошуку потрібно додати деяку інформацію до медіафайлів.
                </Alert>

                <MediaDataModalItems />
            </Modal.Body>
            <Modal.Footer>
                <Form.Submit>Зберегти</Form.Submit>
            </Modal.Footer>
        </Form.Callback>
    )
}


const MediaDataModal = ({ state }: MediaDataModalProps) => {
    return (
        <Modal state={state} title='Потрібно додати деяку інформацію...' lockClose={true} size='lg'>
            {state.data ? <MediaDataModalBody data={state.data} /> : <BouncingLoader />}
        </Modal>
    )
}

export default MediaDataModal;
