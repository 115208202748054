import {ConvertStatus} from "../types/ConvertStatus";

export const getConvertStatusIcon = (status: ConvertStatus) : string|null => {
    switch (status) {
        case ConvertStatus.Pending:
        case ConvertStatus.Processing:
            return 'mdi mdi-sync text-warning';
        case ConvertStatus.Failed:
            return 'mdi mdi-alert-circle-outline text-danger';
        case ConvertStatus.Completed:
            return 'mdi mdi-check-circle-outline text-success';
        default:
            return null;
    }
}

export const getConvertStatusInfo = (status: ConvertStatus) : string => {
    switch (status) {
        case ConvertStatus.Pending:
            return 'Очікує на конвертацію';
        case ConvertStatus.Processing:
            return 'Конвертується';
        case ConvertStatus.Failed:
            return 'Помилка конвертації';
        case ConvertStatus.Completed:
            return 'Конвертація завершена';
        default:
            return '';
    }
}
